import styled from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";

export const StyledWrapper = styled.h1`
  width:100%;
  align-items: center;
  position: relative;
  color: white;
  text-align: right;
  padding-right: ${Rem(20)};
  margin-top: ${Rem(20)};
  @media(${BP.ipad}) {
    margin-top: ${Rem(44)};
  }
`;


export const StyledTextSmall = styled.div`
  margin-top: ${Rem(20)};
  font-size: ${Rem(18)};
  line-height: ${Rem(18)};
  padding: 0px;
  margin: 0px;
  @media(${BP.ipad}) {
    font-size:${Rem(24)};
    line-height: ${Rem(24)};
  }
`;

export const StyledTextBig = styled.div`
  margin-top: ${Rem(20)};
  font-size: ${Rem(22)};
  line-height: ${Rem(22)};
  padding: 0px;
  margin: 0px;
  @media(${BP.ipad}) {
    font-size:${Rem(32)};
    line-height: ${Rem(32)};
  }
`;
